<template>
    <v-container class="px-6" v-if="shipment">
        <v-row>
            <v-col cols="12">
                <span>Elija un motivo</span>
            </v-col>
        </v-row>

        <v-row v-for="reason in failureReasons" v-bind:key="reason.id">
            <v-col cols="12">
                <v-btn outlined block x-large @click="onFailureReasonSelected(reason)">{{ reason }}</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        components: {
        },

        props: {
            shipment: {
                type: Object,
                default: null
            },

            active: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                failureReasons: [
                    'No se encontraba',
                    'No lo aceptaron',
                    'Inconvenientes con el vehiculo',
                    'No existe la dirección'
                ]
            };
        },

        computed: {
            isLoading() {
                return this.$store.getters['shipment/isLoading']();
            }
        },

        watch: {

        },

        methods: {
            async onFailureReasonSelected(reason) {
                await this.$store.dispatch('shipments/return', {
                    shipment: this.shipment,
                    reason: reason
                });

                this.$emit('submit');
            }
        }
    };
</script>
