<template>
    <template-layout>
        <template slot="content">
            <v-navigation-drawer
                v-if="$vuetify.breakpoint.mobile"
                v-model="showRouteMap"
                disable-resize-watcher
                right
                fixed
                :app="$vuetify.breakpoint.xlOnly"
                :clipped="$vuetify.breakpoint.xlOnly"
                :temporary="$vuetify.breakpoint.lgAndDown"
                width="800"
            >
                <div class="google-map" style="height: 100%;" id="directions-map"></div>
            </v-navigation-drawer>

            <delivery-drawer
                v-model="showDeliveryForm"
                :shipment="selectedShipment"
            ></delivery-drawer>

            <v-container class="hidden-print-only pa-3">
                <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                    <v-toolbar-title>
                        <span class="headline">Entregas - {{ selectedDate | moment('dddd D MMMM') | capitalize }}</span>
                    </v-toolbar-title>

                    <v-toolbar-title>
                        <v-menu v-model="dateMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" icon><v-icon>mdi-calendar-edit</v-icon></v-btn>
                            </template>

                            <v-date-picker v-model="selectedDate"
                                           @input="dateMenu = false"
                                           no-title
                                           :first-day-of-week="1"
                                           locale="es-AR"
                                           event-color="green lighten-1">
                            </v-date-picker>
                        </v-menu>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-toolbar-title v-if="!$vuetify.breakpoint.mobile">
                        <v-btn icon @click="onPrintClicked" class="d-none d-md-flex"><v-icon>mdi-printer</v-icon></v-btn>
                    </v-toolbar-title>

                    <v-toolbar-title v-if="$vuetify.breakpoint.mobile">
                        <v-btn icon @click="onMapSelected"><v-icon>mdi-map</v-icon></v-btn>
                    </v-toolbar-title>
                </v-toolbar>

                <v-alert
                    v-if="draft"
                    border="left"
                    color="orange"
                    dense
                    text
                    type="info"
                >
                    Esta ruta no esta confirmada y debe ser considerada un borrador
                </v-alert>

                <v-alert
                    v-if="routes && routes.length == 0"
                    border="left"
                    color="red"
                    dense
                    text
                    type="info"
                >
                    No hay pedidos para entregar
                </v-alert>

                <v-alert
                    v-if="routes && routes.unassigned && routes.unassigned.length > 0"
                    border="left"
                    color="red"
                    dense
                    text
                    type="info"
                >
                    Hay {{ routes.unassigned.length }} pedidos sin poder ser entregados: <span v-for="item in routes.unassigned" v-bind:key=item.id>{{ item.id }}, </span>
                </v-alert>

                <v-alert
                    v-if="this.errorCode"
                    border="left"
                    color="red"
                    dense
                    text
                    type="info"
                >
                    {{ $t(this.errorCode) }}
                </v-alert>

                <v-row>
                    <v-col
                        cols="12"
                        md="6"
                    >
                        <v-card
                            class="overflow-auto elevation-2"
                            style="height: 70vh;"
                        >
                            <v-card-title>Recorrido</v-card-title>

                            <!-- <v-card-text> -->
                                <v-expansion-panels tile v-if="routes" v-model="expandedVehicle">
                                    <v-expansion-panel v-for="route in routes" :key="route.vehicle.id">
                                        <v-expansion-panel-header class="px-0 px-md-6">
                                            <v-col cols="12">
                                                <span class="text-body-1 text-xl-h5" v-if="route.vehicle">
                                                    <v-badge v-if="!$vuetify.breakpoint.mobile" inline :color="vehicleColors[route.vehicle.id - 1]" />
                                                    {{ route.vehicle.name }}
                                                </span>
                                            </v-col>

                                            <v-col cols="3" sm="4">
                                                <h5 class="font-weight-regular grey--text">Pedidos</h5>
                                                <span class="text-body-1 text-xl-h4" v-if="route.shipments">{{ route.shipments.length }}</span>
                                            </v-col>

                                            <v-col cols="4" sm="4" v-if="!$vuetify.breakpoint.mobile">
                                                <h5 class="font-weight-regular grey--text">Distancia</h5>
                                                <span class="text-body-1 text-xl-h4">{{ route.distance | toKilometers }}km.</span>
                                            </v-col>

                                            <v-col cols="5" sm="4">
                                                <h5 class="font-weight-regular grey--text">Tiempo</h5>
                                                <span class="text-body-1 text-xl-h4">{{ route.duration | toHoursAndMinutes }}</span>
                                            </v-col>
                                        </v-expansion-panel-header>

                                        <v-expansion-panel-content>
                                            <v-simple-table>
                                                <tbody>
                                                    <tr>
                                                        <td class="caption px-0 pr-4 py-4" style="vertical-align: top">
                                                            <span class="d-block">{{ route.estimatedStart | moment('HH:mm') }}</span>
                                                        </td>

                                                        <td class="px-0 py-4" style="vertical-align: top">
                                                            <span class="subtitle-2 d-block">Inicio del recorrido</span>
                                                        </td>
                                                    </tr>

                                                    <tr v-for="(step, index) in route.shipments"
                                                        v-bind:key="index"
                                                        @mouseover="highlightRouteStep(route.vehicle.id, index)"
                                                        @mouseleave="highlightRouteStep(route.vehicle.id, -1)"
                                                        @click="onShipmentSelected(getShipment(step))"
                                                    >
                                                        <template>
                                                            <td class="caption px-0 py-4" style="vertical-align: top">
                                                                <span class="d-block">{{ getShipment(step).estimatedDeliveryTime | moment('HH:mm') }}</span>
                                                            </td>

                                                            <td class="py-4 px-0" v-if="getOrderForShipment(step) && getCustomerForShipment(step)">
                                                                <span class="subtitle-2 d-block">#{{ getOrderForShipment(step).id }} - {{ getCustomerForShipment(step).fullName | capitalize }}</span>
                                                                <span class="caption">
                                                                    {{ getShippingAddressForShipment(step) }}
                                                                </span>
                                                            </td>

                                                            <td v-if="getShipment(step)">
                                                                <v-badge v-if="getShipment(step).state === 'state.delivered'" dot color="primary"></v-badge>
                                                                <v-badge v-else-if="getShipment(step).state === 'state.returned'" dot color="red"></v-badge>
                                                            </td>
                                                        </template>
                                                    </tr>

                                                    <tr>
                                                        <td class="caption px-0 py-4" style="vertical-align: top">
                                                            <span class="d-block">{{ route.estimatedEnd | moment('HH:mm') }}</span>
                                                        </td>

                                                        <td class="px-0 py-4">
                                                            <span class="subtitle-2 d-block" style="vertical-align: top">Fin del recorrido</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </v-simple-table>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            <!-- </v-card-text> -->
                        </v-card>
                    </v-col>

                    <v-col
                        v-if="!$vuetify.breakpoint.mobile"
                        cols="12"
                        md="6"
                    >
                        <div class="google-map" style="height: 100%;" id="directions-map"></div>
                    </v-col>
                </v-row>
            </v-container>

            <v-container class="hidden-screen-only">
                <template v-if="selectedRoute">
                    <h4 class="title mb-2">Recorrido {{ selectedRoute.vehicle.name }} del {{ selectedRoute.date }}</h4>

                    <v-row v-for="schedule in selectedRoute.schedules" v-bind:key="schedule.start">
                        <v-col cols="12">
                            <h4 class="title mb-2">Recorrido {{ schedule.start }}</h4>

                            <v-simple-table class="print-table">
                                <tbody style="vertical-align: top">
                                    <tr v-for="(stop, index) in schedule.stops" v-bind:key="index">
                                        <td style="width: 10%">
                                            <span v-if="stop.duration">
                                                {{ stop.duration.text }}
                                            </span>
                                        </td>

                                        <td style="width: 20%">
                                            #{{ stop.order.id }} - {{ stop.customer.name | capitalize }}
                                        </td>

                                        <td style="width: 40%">
                                            <span v-if="stop.address.place || stop.address.area">
                                                {{ stop.address.place }} {{ stop.address.area }} <br/>
                                            </span>

                                            <span v-if="stop.address.address">
                                                {{ stop.address.address }} <br/>
                                            </span>

                                            <span v-if="stop.address.unit">
                                                {{ stop.address.unit }} <br/>
                                            </span>

                                            <span v-if="stop.address.phone || stop.address.cellphone">
                                                Contacto: {{ stop.address.phone }} / {{ stop.address.cellphone }} <br/>
                                            </span>

                                            <span>
                                                {{ stop.address.notes }}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </template>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    /* global google */
    import TemplateLayout from '@/layout/TemplateLayout';
    import DeliveryDrawer from './DeliveryDrawer';

    export default {
        components: {
            TemplateLayout,
            DeliveryDrawer
        },

        data() {
            return {
                headers: [
                    { text: 'Pedido #', value: 'id' },
                    { text: 'Fecha', value: 'deliveryDate' },
                    { text: 'Móvil', value: 'vehicle.name' },
                    { text: 'Cliente', value: 'customer' },
                    { text: 'Total', value: 'total' },
                    { text: 'Pago', value: 'payment' },
                    { text: '', value: 'actions' }
                ],

                orderAvailableDates: [],

                selectedVehicle: -1,
                selectedSchedule: -1,
                expandedVehicle: -1,

                selectedDate: this.$moment().format('YYYY-MM-DD'),
                dateMenu: false,

                showDeliveryForm: false,
                showRouteMap: false,

                selectedShipment: null,
                selectedCustomer: null,

                selectedRoute: null,
                selectedRouteScheduleStops: null,
                selectedRouteDetail: null,

                startLocation: new google.maps.LatLng(-34.4990746, -59.0867709),

                loadingRoutes: false,
                loadingDirections: false,

                vehicleColors: [
                    '#DA4453',
                    '#967ADC',
                    '#4A89DC',
                    '#F6BB42',
                    '#8CC152',
                    '#37BC9B',
                    '#F6BB42'
                ],
                mapPolylines: [],
                mapMarkers: [],

                errorCode: null,

                mapStyle: [
                    {
                        featureType: 'administrative',
                        elementType: 'all',
                        stylers: [
                            {
                                saturation: '-100'
                            }
                        ]
                    },
                    {
                        featureType: 'administrative.province',
                        elementType: 'all',
                        stylers: [
                            {
                                visibility: 'off'
                            }
                        ]
                    },
                    {
                        featureType: 'landscape',
                        elementType: 'all',
                        stylers: [
                            {
                                saturation: -100
                            },
                            {
                                lightness: 65
                            },
                            {
                                visibility: 'on'
                            }
                        ]
                    },
                    {
                        featureType: 'poi',
                        elementType: 'all',
                        stylers: [
                            {
                                saturation: -100
                            },
                            {
                                lightness: '50'
                            },
                            {
                                visibility: 'simplified'
                            }
                        ]
                    },
                    {
                        featureType: 'road',
                        elementType: 'all',
                        stylers: [
                            {
                                saturation: '-100'
                            }
                        ]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'all',
                        stylers: [
                            {
                                visibility: 'simplified'
                            }
                        ]
                    },
                    {
                        featureType: 'road.arterial',
                        elementType: 'all',
                        stylers: [
                            {
                                lightness: '30'
                            }
                        ]
                    },
                    {
                        featureType: 'road.local',
                        elementType: 'all',
                        stylers: [
                            {
                                lightness: '40'
                            }
                        ]
                    },
                    {
                        featureType: 'transit',
                        elementType: 'all',
                        stylers: [
                            {
                                saturation: -100
                            },
                            {
                                visibility: 'simplified'
                            }
                        ]
                    },
                    {
                        featureType: 'water',
                        elementType: 'geometry',
                        stylers: [
                            {
                                hue: '#ffff00'
                            },
                            {
                                lightness: -25
                            },
                            {
                                saturation: -97
                            }
                        ]
                    },
                    {
                        featureType: 'water',
                        elementType: 'labels',
                        stylers: [
                            {
                                lightness: -25
                            },
                            {
                                saturation: -100
                            }
                        ]
                    }
                ]
            };
        },

        filters: {
            toKilometers: function(value) {
                return Math.trunc(value / 1000);
            },

            toMinutes: function(value) {
                return Math.trunc(value / 60);
            },

            toHoursAndMinutes: function(value) {
                let totalMinutes = value / 60;

                let hours = Math.trunc(totalMinutes / 60);
                let minutes = Math.trunc(totalMinutes % 60);
                return hours + 'h ' + minutes + 'm';
            }
        },

        computed: {
            isLoading() {
                return this.$store.getters['order/isLoading']();
            },

            routes() {
                return this.$store.getters['deliveryRoutes/getAll']();
            },

            draft() {
                return this.$store.getters['deliveryRoutes/isDraft']();
            }
        },

        watch: {
            showDeliveryForm(val) {
                if(val) {
                    this.showRouteMap = false;
                }

                if(!val) {
                    this.selectedShipment = null;
                }
            },

            showRouteMap(val) {
                if(val) {
                    this.showDeliveryForm = false;
                }
            },

            async routes(val) {
                for(let vehicle in this.mapPolylines) {
                    let polyline = this.mapPolylines[vehicle];
                    polyline.setMap(null);
                }
                for(let vehicle in this.mapMarkers) {
                    let markers = this.mapMarkers[vehicle];
                    for(let m of markers) {
                        m.setMap(null);
                    }
                }

                this.mapPolylines = {};
                this.mapMarkers = {};

                let bounds = new google.maps.LatLngBounds();

                for(let route of val) {
                    let vehicleId = route.vehicle.id;
                    let color = this.vehicleColors[vehicleId - 1];

                    let encodedPolyline = String(route.encodedPolyline);
                    let decodedPath = google.maps.geometry.encoding.decodePath(encodedPolyline);

                    let region = new google.maps.Polyline({
                        path: decodedPath,
                        strokeColor: color,
                        strokeOpacity: 1.0,
                        strokeWeight: 5
                    });
                    region.setMap(this.map);
                    this.mapPolylines[vehicleId] = region;

                    this.mapMarkers[vehicleId] = [];

                    // add a marker to each stop
                    for(let [index, step] of route.shipments.entries()) {
                        let shipment = this.getShipment(step);
                        let address = shipment.shippingAddress;

                        // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
                        var markerImage = {
                            path: 'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z',
                            anchor: new google.maps.Point(12, 17),
                            fillOpacity: 1,
                            fillColor: color,
                            strokeWeight: 2,
                            strokeColor: 'white',
                            scale: 1.5,
                            labelOrigin: new google.maps.Point(12, 9)
                        };

                        // https://developers.google.com/maps/documentation/javascript/reference/marker#Symbol
                        var label = {
                            text: index.toString(),
                            color: 'white',
                            fontSize: '12px'
                        };

                        let marker = new google.maps.Marker({
                            map: this.map,
                            label: label,
                            position: new google.maps.LatLng(address.location.lat, address.location.lng),
                            icon: markerImage
                            // animation: google.maps.Animation.DROP
                        });

                        this.mapMarkers[vehicleId].push(marker);
                    }

                    // extend our bounds
                    region.getPath().forEach(function(latLng) {
                        bounds.extend(latLng);
                    });
                }

                this.map.fitBounds(bounds);
            },

            selectedDate: {
                immediate: true,
                handler(val) {
                    this.$store.dispatch('deliveryRoutes/clear');

                    this.selectedVehicle = -1;

                    this.updateRouteQuery();

                    this.fetchRoutes();
                }
            },

            expandedVehicle(val) {
                let route = this.routes[val];

                if(route && route.vehicle) {
                    this.highlightRoute(route.vehicle.id);
                }
                else {
                    this.highlightRoute(-1);
                }
            }
        },

        mounted() {
            this.$store.dispatch('vehicles/fetchAll');

            let date = this.$route.query.date ? this.$route.query.date : null;
            if(date) {
                this.selectedDate = date;
            }

            this.map = new google.maps.Map(document.getElementById('directions-map'), {
                zoom: 9,
                center: this.startLocation,
                disableDefaultUI: true
            });

            this.map.setOptions({
                styles: this.mapStyle
            });
        },

        methods: {
            getOrderForShipment(id) {
                let shipment = this.getShipment(id);
                return shipment ? this.$store.getters['order/getById'](shipment.orderId) : null;
            },

            getCustomerForShipment(id) {
                let order = this.getOrderForShipment(id);
                return order ? this.$store.getters['customer/getById'](order.customer) : null;
            },

            getShipment(id) {
                return id ? this.$store.getters['shipments/getById'](id) : null;
            },

            getShippingAddressForShipment(id) {
                let shipment = this.getShipment(id);

                let result = '';
                if(shipment.shippingAddress) {
                    let address = shipment.shippingAddress;

                    if(address.place) {
                        result += address.place;
                    }

                    result += address.address.split(',', 2)[0] + '.' + address.address.split(',', 2)[1] + '.';
                    result += 'Unidad: ' + address.unit;
                }

                return result;
            },

            updateRouteQuery() {
                let q = {};

                if(this.selectedDate) {
                    q.date = this.selectedDate;
                }

                this.$router.replace({ name: 'deliveryRoutesList', query: q }).catch(() => {});
            },

            async fetchRoutes() {
                this.loadingRoutes = true;

                this.errorCode = null;

                try {
                    await this.$store.dispatch('deliveryRoutes/fetchAll', {
                        date: this.selectedDate
                    });

                    let orderIds = this.routes.map(r => r.shipments.map(s => this.getShipment(s).orderId));
                    await this.$store.dispatch('order/fetchMany', {
                        ids: orderIds
                    });
                }
                catch (err) {
                    this.errorCode = err.code;
                }

                this.loadingRoutes = false;
            },

            onMapSelected() {
                if(!this.showRouteMap) {
                    this.showDeliveryForm = false;
                }

                this.$nextTick(() => {
                    this.showRouteMap = !this.showRouteMap;
                });
            },

            async onShipmentSelected(shipment) {
                if(!shipment) {
                    return;
                }

                this.showDeliveryForm = true;

                // Fetch the latest data for the order before paying.
                await this.$store.dispatch('order/fetchOne', { id: shipment.orderId });
                let order = this.$store.getters['order/getById'](shipment.orderId);

                // Fetch comments
                await this.$store.dispatch('order/fetchComments', { order: order });

                this.selectedShipment = shipment;
            },

            onPrintClicked() {
                window.print();
            },

            highlightRoute(val) {
                // update color of polylines
                for(let vehicleId in this.mapPolylines) {
                    let map = (val === -1 || val === parseInt(vehicleId)) ? this.map : null;

                    this.mapPolylines[vehicleId].setMap(map);

                    let markers = this.mapMarkers[vehicleId];
                    for(let marker of markers) {
                        marker.setMap(map);
                    }
                }

                // Adjust bounds
                if(val !== -1) {
                    let polyline = this.mapPolylines[val];
                    let bounds = new google.maps.LatLngBounds();
                    polyline.getPath().forEach(function(latLng) {
                        bounds.extend(latLng);
                    });
                    this.map.fitBounds(bounds);
                }
                else {
                    let bounds = new google.maps.LatLngBounds();

                    for(let vehicleId in this.mapPolylines) {
                        let polyline = this.mapPolylines[vehicleId];
                        polyline.getPath().forEach(function(latLng) {
                            bounds.extend(latLng);
                        });
                    }

                    this.map.fitBounds(bounds);
                }
            },

            highlightRouteStep(routeIndex, stepIndex) {
                let color = this.vehicleColors[routeIndex - 1];

                let markers = this.mapMarkers[routeIndex];
                for(let i = 0; i < markers.length; ++i) {
                    let marker = markers[i];

                    if(stepIndex === i || stepIndex === -1) {
                        marker.icon.fillColor = color;
                        marker.setIcon(marker.icon);
                        marker.setZIndex(10000);

                        this.map.setCenter(marker.position);
                    }
                    else {
                        marker.icon.fillColor = '#cccccc';
                        marker.setIcon(marker.icon);
                        marker.setZIndex(0);
                    }
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .print-table {
    td {
            border-bottom: 1px solid black !important;
        }
    }
</style>
