<template>
    <v-container>
    </v-container>
</template>

<script>
    import qs from 'qs';

    export default {
        components: {
        },

        data() {
            return {
            };
        },

        props: {
            order: {
                type: Object
            },

            amount: {
                type: Number
            }
        },

        methods: {
            async submit() {
                let customer = this.$store.getters['customer/getById'](this.order.customer);

                let mpUrl = 'https://www.mercadopago.com/point/integrations';
                let args = {
                    amount: this.amount,
                    description: 'Pedido ' + this.order.id,
                    external_reference: this.order.id,
                    payer_email: customer.email,
                    notification_url: process.env.VUE_APP_TALLO_API_URL + '/api/payments/mp_notification',
                    client_id: '8763121418082753',
                    client_secret: '52AjRvn4jhreztQaRoliQLUdrHCocRw6'
                };

                window.open(mpUrl + '?' + qs.stringify(args, { encodeValuesOnly: true }));
            }
        }
    };
</script>
