<template>
    <v-navigation-drawer
        v-model="active"
        right
        fixed
        temporary
        width="600"
    >
        <v-container class="pa-10" v-if="order">
            <v-row>
                <v-col>
                    <v-row class="align-center">
                        <v-btn icon class="black--text" @click="onBackSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline">Pedido #{{ order.id }}</span>
                    </v-row>
                </v-col>
            </v-row>

            <transition :name="transitionName" mode="out-in">
                <div key="step1" v-if="step == DeliveryStep.Initial">
                    <delivery-drawer-initial-step
                        :shipment="shipment"
                        @pay="onPaySelected"
                        @deliver="onDeliverSelected"
                        @failed="onDeliverFailedSelected"
                    />
                </div>

                <div key="step2" v-if="step === DeliveryStep.Failed">
                    <delivery-drawer-failed-step
                        :active.sync="active"
                        :shipment="shipment"
                        @submit="onDeliverySubmit"
                    />
                </div>

                <div key="step3" v-if="step === DeliveryStep.Payment">
                    <delivery-drawer-payment-step
                        :order="order"
                        @submit="onPaymentSubmit"
                    />
                </div>

                <div key="step4" v-if="step === DeliveryStep.Confirm">
                    <delivery-drawer-confirm-step
                        :shipment="shipment"
                        @submit="onDeliverySubmit"
                    />
                </div>
            </transition>
        </v-container>
    </v-navigation-drawer>
</template>

<script>
    import DeliveryDrawerFailedStep from './DeliveryDrawerFailedStep';
    import DeliveryDrawerInitialStep from './DeliveryDrawerInitialStep';
    import DeliveryDrawerPaymentStep from './DeliveryDrawerPaymentStep';
    import DeliveryDrawerConfirmStep from './DeliveryDrawerConfirmStep';

    const DeliveryStep = Object.freeze({
        Initial: 1,
        Failed: 2,
        Payment: 3,
        Confirm: 4
    });

    export default {
        components: {
            DeliveryDrawerInitialStep,
            DeliveryDrawerFailedStep,
            DeliveryDrawerPaymentStep,
            DeliveryDrawerConfirmStep
        },

        props: {
            value: {
                type: Boolean,
                default: false
            },

            shipment: {
                type: Object,
                default: null
            }
        },

        data() {
            return {
                active: false,

                transitionName: 'slide-left',

                DeliveryStep,
                step: DeliveryStep.Initial,
                delivered: true,
                selectedFailureReason: null,
                selectedPaymentMethod: null
            };
        },

        computed: {
            order() {
                return this.shipment ? this.$store.getters['order/getById'](this.shipment.orderId) : null;
            },

            isLoading() {
                return this.$store.getters['order/isLoading']();
            },

            disabled() {
                return false;
            }
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.step = DeliveryStep.Initial;
                this.active = val;
            }
        },

        methods: {
            onDrawerClosed() {
                this.step = DeliveryStep.Initial;
            },

            onDeliverSelected() {
                this.transitionName = 'slide-left';

                if(this.order.paidUp !== this.order.total) {
                    this.step = DeliveryStep.Payment;
                }
                else {
                    this.step = DeliveryStep.Confirm;
                }
            },

            onPaySelected() {
                this.transitionName = 'slide-left';
                this.step = DeliveryStep.Payment;
            },

            onDeliverFailedSelected() {
                this.transitionName = 'slide-left';
                this.step = DeliveryStep.Failed;
            },

            async onPaymentSubmit() {
                // re-fetch order after payment
                await this.$store.dispatch('order/fetchOne', { id: this.order.id });

                this.transitionName = 'slide-left';
                this.step = DeliveryStep.Confirm;
            },

            onBackSelected(value) {
                this.transitionName = 'slide-right';

                switch (this.step) {
                case DeliveryStep.Initial:
                    this.active = false;
                    break;
                case DeliveryStep.Failed:
                    this.step = DeliveryStep.Initial;
                    break;
                case DeliveryStep.Payment:
                    this.step = DeliveryStep.Initial;
                    break;
                case DeliveryStep.Confirm:
                    this.active = false;
                    break;
                }
            },

            onDeliverySubmit() {
                this.active = false;
            }
        }
    };
</script>

<style>
    .slide-left-leave-active,
    .slide-left-enter-active {
        transition: 0.2s;
    }
    .slide-left-enter {
        opacity: 0;
        transform: translate(100%, 0);
    }
    .slide-left-leave-to {
        transform: translate(-100%, 0);
        opacity: 0;
    }

    .slide-right-leave-active,
    .slide-right-enter-active {
        transition: 0.2s;
    }
    .slide-right-enter {
        opacity: 0;
        transform: translate(-100%, 0);
    }
    .slide-right-leave-to {
        transform: translate(100%, 0);
        opacity: 0;
    }
</style>
