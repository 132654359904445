<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <span class="subtitle-1 font-weight-medium"><v-icon class="mr-2">mdi-text-subject</v-icon>Resumen</span>

                <v-list dense class="t-info-list">
                    <v-list-item v-if="customer">
                        <v-list-item-content>Cliente</v-list-item-content>
                        <v-list-item-content class="align-end">{{ customer.fullName | capitalize }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>Total</v-list-item-content>
                        <v-list-item-content class="align-end">{{ this.order.total | toCurrency }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>Estado</v-list-item-content>
                        <v-list-item-content class="align-end">
                            <tui-order-payment-status-chip :order="this.order"></tui-order-payment-status-chip>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>Deuda</v-list-item-content>
                        <v-list-item-content class="align-end">{{ (this.order.total - this.order.paidUp) | toCurrency }}</v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>

        <v-spacer></v-spacer>

        <v-row>
            <v-col>
                <v-btn color="green" block dark @click="onDeliverSelected">Confirmar</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import TuiOrderPaymentStatusChip from '@/ui/TUIOrderPaymentStatusChip';

    export default {
        components: {
            TuiOrderPaymentStatusChip
        },

        props: {
            shipment: {
                type: Object,
                default: null
            }
        },

        data() {
            return {

            };
        },

        computed: {
            order() {
                return this.shipment ? this.$store.getters['order/getById'](this.shipment.orderId) : null;
            },

            customer() {
                return this.order ? this.$store.getters['customer/getById'](this.order.customer) : null;
            }
        },

        watch: {

        },

        methods: {
            async onDeliverSelected() {
                await this.$store.dispatch('shipments/deliver', {
                    shipment: this.shipment
                });

                this.$emit('submit');
            }
        }
    };
</script>
