<template>
    <v-container v-if="order">
        <template v-if="selectedPaymentMethod == null">
            <v-row>
                <v-col>
                    <span class="title-2">Elija el medio de pago</span>
                </v-col>
            </v-row>

            <v-row>
                <delivery-payment-drawer-methods-list
                    :paymentMethods="paymentMethods"
                    :savedCards="savedCards"
                    v-model="selectedPaymentMethod"
                />
            </v-row>

            <v-row>
                <v-col>
                    <v-btn outlined block x-large color="red" @click="onNoPaymentSelected">
                        <v-icon class="mr-2">mdi-credit-card-outline</v-icon>
                        Continuar sin pago
                    </v-btn>
                </v-col>
            </v-row>
        </template>

        <template v-else>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        label="Monto"
                        v-model="amountPaid"
                        prefix="$"
                        :hint="hint"
                        persistent-hint
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <component
                    ref="paymentForm"
                    v-bind:is="currentFormComponent"
                    :order="order"
                    :amount.sync="amountPaid"
                    :method="selectedPaymentMethod"
                ></component>
            </v-row>

            <v-container class="footer" v-if="selectedPaymentMethod">
                <v-row>
                    <v-col>
                        <v-btn color="primary" x-large block @click="onContinueSelected" :loading="isSaving" :disabled="disabled">
                            Continuar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </v-container>
</template>

<script>
    import DeliveryPaymentDrawerMethodsList from './DeliveryPaymentDrawerMethodsList';
    import PaymentCashForm from '@/components/Payment/PaymentCashForm.vue';
    import PaymentCreditCardForm from '@/components/Payment/PaymentCreditCardForm.vue';
    import PaymentSavedCreditCardForm from '@/components/Payment/PaymentSavedCreditCardForm.vue';
    import PaymentWireTransferForm from '@/components/Payment/PaymentWireTransferForm.vue';
    import PaymentCheckForm from '@/components/Payment/PaymentCheckForm.vue';
    import PaymentMercadoPointForm from '@/components/Payment/PaymentMercadoPointForm.vue';

    export default {
        components: {
            PaymentCashForm,
            PaymentCreditCardForm,
            PaymentWireTransferForm,
            PaymentSavedCreditCardForm,
            PaymentCheckForm,
            PaymentMercadoPointForm,
            DeliveryPaymentDrawerMethodsList
        },

        props: {
            order: {
                type: Object,
                default: null
            },

            active: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                isLoading: false,
                isSaving: false,

                paymentMethods: [],
                savedCards: [],

                amountPaid: 0,
                selectedPaymentMethod: null
            };
        },

        computed: {
            hint() {
                return 'Pendiente de pago: ' + this.$options.filters.toCurrency(this.order.total - this.order.paidUp);
            },

            drawerActive: {
                get() {
                    return this.active;
                },

                set(val) {
                    this.$emit('update:active', val);
                }
            },

            paymentMethodName() {
                if(!this.selectedPaymentMethod) {
                    return 'N/A';
                }

                if(this.selectedPaymentMethod.last) {
                    return this.$options.filters.capitalize(this.selectedPaymentMethod.name) + ' terminada en ' + this.selectedPaymentMethod.last;
                }

                return this.selectedPaymentMethod.name;
            },

            disabled() {
                return false;
            },

            currentFormComponent() {
                if(this.selectedPaymentMethod) {
                    switch (this.selectedPaymentMethod.id) {
                    case 1:
                        return 'PaymentCashForm';
                    case 5:
                        if(this.selectedPaymentMethod.last) {
                            return 'PaymentSavedCreditCardForm';
                        }
                        else {
                            return 'PaymentCreditCardForm';
                        }
                    case 7:
                        return 'PaymentMercadoPointForm';
                    default:
                        return '';
                    }
                }
                return '';
            }
        },

        watch: {
            drawerActive: {
                immediate: true,
                handler(val) {
                    if(!val) {
                        this.onDrawerClosed();
                    }
                }
            },

            order: {
                immediate: true,
                handler(val) {
                    if(!val) {
                        return;
                    }

                    this.selectedPaymentMethod = null;
                    this.amountPaid = this.order.total - this.order.paidUp;

                    this.isLoading = true;
                    this.$api.getCustomerPaymentMethods(this.order.customer).then((response) => {
                        this.paymentMethods = response.methods;
                        this.savedCards = response.saved;
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            }
        },

        methods: {
            onDrawerClosed(value) {
                this.selectedPaymentMethod = null;
            },

            onNoPaymentSelected() {
                this.$emit('submit');
            },

            async onContinueSelected() {
                this.isSaving = true;

                await this.$refs.paymentForm.submit();

                this.$emit('submit');
                this.isSaving = false;
            }
        }
    };
</script>
