<template>
    <v-container v-if="paymentMethods">
        <v-row>
            <v-col>
                <v-row v-for="method in savedCards" v-bind:key="'s_' + method.id">
                    <v-col>
                        <v-btn outlined block x-large color="primary" @click="onMethodSelected(method)">
                            <v-icon class="mr-2">mdi-credit-card-outline</v-icon>
                            {{ method.name }} terminada en {{ method.last }}
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row v-for="method in paymentMethods" v-bind:key="'m_' + method.id">
                    <v-col>
                        <v-btn outlined block x-large color="primary" @click="onMethodSelected(method)">
                            <v-icon class="mr-2">{{ iconForMethod(method) }}</v-icon>
                            {{ method.name }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        components: {
        },

        props: {
            savedCards: {
                type: Array,
                default: () => {
                    return [];
                }
            },

            paymentMethods: {
                type: Array,
                default: () => {
                    return [];
                }
            }
        },

        methods: {
            iconForMethod(method) {
                switch (method.id) {
                case 1:
                    return 'mdi-cash-usd-outline';
                case 5:
                    return 'mdi-credit-card-outline';
                default:
                    return '';
                }
            },

            onMethodSelected(method) {
                this.$emit('input', method);
            }
        }
    };
</script>
