<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <span class="subtitle-1 font-weight-medium"><v-icon class="mr-2">mdi-text-subject</v-icon>Resumen</span>

                <v-list dense class="t-info-list">
                    <v-list-item>
                        <v-list-item-content>Cliente</v-list-item-content>
                        <v-list-item-content class="align-end">{{ customer.fullName | capitalize }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>Deuda</v-list-item-content>
                        <v-list-item-content class="align-end">
                            <v-chip label x-small-chip :color="debtColor" text-color="white">
                                {{ this.order.total - this.order.paidUp | toCurrency }}
                            </v-chip>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <span class="subtitle-1 font-weight-medium"><v-icon class="mr-2">mdi-truck-outline</v-icon>Entrega</span>

                <v-list dense class="t-info-list">
                    <div class="google-map" style="height: 200px;" id="detail-map"></div>

                    <v-list-item>
                        <v-list-item-content>Dirección</v-list-item-content>
                        <v-list-item-content class="align-end">
                            <a target="_blank" :href="'https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=' + shipment.shippingAddress.location.lat + ',' + shipment.shippingAddress.location.lng">
                                {{ shipment.shippingAddress.address }}
                            </a>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="shipment.shippingAddress.place">
                        <v-list-item-content>Lugar</v-list-item-content>
                        <v-list-item-content>{{ shipment.shippingAddress.place }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>Contacto</v-list-item-content>
                        <v-list-item-content>
                            <template v-if="shipment.shippingAddress.cellphone">
                                <span style="line-height: 24px;">
                                    <v-icon class="mr-2">mdi-cellphone</v-icon>
                                    <a :href="'tel:' + shipment.shippingAddress.cellphone" class="d-inline">{{ shipment.shippingAddress.cellphone }}</a>
                                </span>
                            </template>
                            <template v-if="shipment.shippingAddress.phone">
                                <span style="line-height: 24px;">
                                    <v-icon class="mr-2">mdi-phone-classic</v-icon>
                                    <a :href="'tel:' + shipment.shippingAddress.phone">{{ shipment.shippingAddress.phone }}</a>
                                </span>
                            </template>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <span class="subtitle-1 font-weight-medium"><v-icon class="mr-2">mdi-comment-outline</v-icon>Comentarios</span>
            </v-col>

            <v-col cols="12">
                <order-comments :order="order"></order-comments>
            </v-col>
        </v-row>

        <v-spacer></v-spacer>

        <v-row v-if="shipment.state === 'state.transit'">
            <v-col>
                <v-btn color="red" block dark @click="onDeliverFailedSelected">Entrega Fallida</v-btn>
            </v-col>
            <v-col>
                <v-btn color="green" block dark @click="onDeliverSelected">Entregar</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    /* global google */
    import OrderComments from '@/components/Order/OrderComments.vue';

    export default {
        components: {
            OrderComments
        },

        props: {
            shipment: {
                type: Object,
                default: null
            }
        },

        data() {
            return {
                map: null
            };
        },

        computed: {
            customer() {
                return this.$store.getters['customer/getById'](this.order.customer);
            },

            order() {
                return this.shipment ? this.$store.getters['order/getById'](this.shipment.orderId) : null;
            },

            requiresPayment() {
                return this.order.paidUp < this.order.total;
            },

            debtColor() {
                if(this.order.paidUp === 0 && this.order.total > 0) {
                    return 'red';
                }
                else if(this.order.paidUp < this.order.total) {
                    return 'orange';
                }
                else if(this.order.paidUp >= this.order.total) {
                    return 'green';
                }
                else {
                    return 'red';
                }
            }
        },

        watch: {
            shipment(val) {
                if(this.marker) {
                    this.marker.setMap(null);
                    this.marker = null;
                }

                let position = this.shipment.shippingAddress.location;
                this.marker = new google.maps.Marker({
                    map: this.map,
                    position: position
                });

                this.map.setCenter(position);
                this.map.setZoom(16);
            }
        },

        mounted() {
            let position = this.shipment.shippingAddress.location;

            this.map = new google.maps.Map(document.getElementById('detail-map'), {
                zoom: 16,
                center: position,
                disableDefaultUI: true
            });
        },

        methods: {
            onDeliverSelected() {
                if(this.requiresPayment) {
                    this.$emit('pay');
                }
                else {
                    this.$emit('deliver');
                }
            },

            onDeliverFailedSelected() {
                this.$emit('failed');
            }
        }
    };
</script>
