<template>
    <validation-observer ref="form">
        <form>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <validation-provider name="ccNumber" v-slot="{ errors }" rules="required">
                            <v-text-field
                                v-model="ccNumber"
                                id="cc-num"
                                name="ccnumber"
                                autocomplete="cc-number"
                                x-autocompletetype="cc-number"
                                :label="$t('fields.ccNumber')"
                                v-mask="maskCCNumber"
                                :error-messages="errors"
                                pattern="\d*"
                            />
                        </validation-provider>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <validation-provider name="ccCardholderName" v-slot="{ errors }" rules="required">
                            <v-text-field
                                v-model="ccName"
                                id="cc-name"
                                name="nameoncard"
                                autocomplete="cc-name"
                                x-autocompletetype="cc-full-name"
                                :label="$t('fields.ccCardholderName')"
                                :error-messages="errors"
                            />
                        </validation-provider>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6">
                        <validation-provider name="ccExp" v-slot="{ errors }" rules="required">
                            <v-text-field
                                v-model="ccExp"
                                id="cc-exp"
                                autocomplete="cc-exp"
                                x-autocompletetype="cc-exp"
                                :label="$t('fields.ccExp')"
                                hint="MM/AA"
                                :error-messages="errors"
                                v-mask="maskExpiration"
                                pattern="\d*"
                            />
                        </validation-provider>
                    </v-col>

                    <v-col cols="6">
                        <validation-provider name="ccSecurityCode" v-slot="{ errors }" rules="required">
                            <v-text-field
                                v-model="ccCSC"
                                id="cc-csc"
                                name="cc-csc"
                                autocomplete="cc-csc"
                                x-autocompletetype="cc-csc"
                                :label="$t('fields.ccSecurityCode')"
                                :error-messages="errors"
                                v-mask="maskCSC"
                                pattern="\d*"
                            />
                        </validation-provider>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="4">
                        <validation-provider name="ccDocType" v-slot="{ errors }" rules="required">
                            <v-select
                                v-model="ccDocType"
                                id="doc-type"
                                autocomplete="doc-type"
                                x-autocompletetype="doc-type"
                                :label="$t('fields.ccDocType')"
                                :items="['DNI', 'CI', 'LC', 'LE', 'Otro']"
                                :error-messages="errors"
                            />
                        </validation-provider>
                    </v-col>

                    <v-col cols="8">
                        <validation-provider name="ccDocNumber" v-slot="{ errors }" rules="required">
                            <v-text-field
                                v-model="ccDocNumber"
                                id="doc-number"
                                autocomplete="doc-number"
                                x-autocompletetype="doc-number"
                                :label="$t('fields.ccDocNumber')"
                                :error-messages="errors"
                                pattern="\d*"
                            />
                        </validation-provider>
                    </v-col>
                </v-row>
            </v-container>
        </form>
    </validation-observer>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    /* global Mercadopago */
    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },

        data() {
            return {
                ccType: null,
                ccNumber: null,
                ccExp: null,
                ccCSC: null,
                ccName: null,
                ccDocType: 'DNI',
                ccDocNumber: null,

                ccCSCLength: 4,
                ccNumberLength: 16,
                maskExpiration: '## / ##'
            };
        },

        props: {
            amount: {
                type: Number
            },

            order: {
                type: Object
            }
        },

        computed: {
            maskCSC() {
                return this.ccCSCLength === 3 ? '###' : '####';
            },

            maskCCNumber() {
                switch (this.ccNumberLength) {
                case 15:
                    return '#### ###### #####';
                case 16:
                    return '#### #### #### ####';
                default:
                    return '#### #### #### #### ####';
                }
            }
        },

        watch: {
            ccNumber: {
                handler(val) {
                    if(!val) {
                        return;
                    }

                    // if we have 6 or more digits we can guess the CC type
                    let strippedVal = val.replace(/\s/g, '');
                    if(strippedVal.length >= 6) {
                        Mercadopago.getPaymentMethod({
                            bin: strippedVal.substr(0, 6)
                        }, (status, response) => {
                            if(status === 200 && response) {
                                this.ccType = response[0].id;
                                this.ccNumberLength = response[0].settings[0].card_number.length;
                                this.ccCSCLength = response[0].settings[0].security_code.length;
                            }
                            else {
                                this.ccType = null;
                            }
                        });
                    }
                }
            }
        },

        methods: {
            async submit() {
                // if the CC was not detected properly, just show an error in the number
                if(!this.ccType) {
                    this.$refs.form.setErrors({
                        ccNumber: this.$t('validation.invalid', { _field_: this.$t('fields.ccNumber') })
                    });

                    return;
                }

                let result = await this.$refs.form.validate();
                if(!result) {
                    return;
                }

                let mpToken = await this._getMPToken({
                    number: this.ccNumber,
                    exp: this.ccExp,
                    csc: this.ccCSC,
                    name: this.ccName,
                    docType: this.ccDocType,
                    docNumber: this.ccDocNumber
                });

                await this.$store.dispatch('order/addPayment', {
                    order: this.order,
                    payment: {
                        payment_method_id: 5,
                        payment_amount: this.amount,
                        payment_data: {
                            cc_type: this.ccType,
                            cc_token: mpToken.token
                        }
                    }
                });
            },

            _getMPToken(cc) {
                return new Promise((resolve, reject) => {
                    // Grab the data to send to MP
                    let exp = cc.exp ? cc.exp.replace(/\s/g, '').split('/') : '';
                    let mpFormData = {
                        cardNumber: cc.number.replace(/\s/g, ''),
                        securityCode: cc.csc,
                        cardExpirationMonth: exp[0],
                        cardExpirationYear: exp[1],
                        cardholderName: cc.name,
                        docType: cc.docType,
                        docNumber: cc.docNumber
                    };

                    try {
                        Mercadopago.clearSession();
                        Mercadopago.createToken(mpFormData, (status, response) => {
                            if(status !== 200 && status !== 201) {
                                throw response;
                            }
                            else {
                                resolve({
                                    last_four_digits: response.last_four_digits,
                                    token: response.id
                                });
                            }
                        });
                    }
                    catch (err) {
                        let formErrors = {};

                        for(let cause of err.cause) {
                            switch (cause.code) {
                            case '209':
                            case '325':
                            case '326':
                                formErrors.ccExp = this.$t('validation.invalid', { _field_: this.$t('fields.ccExp') });
                                break;
                            case '205':
                                formErrors.ccNumber = this.$t('validation.invalid', { _field_: this.$t('fields.ccNumber') });
                                break;
                            case '316':
                                formErrors.ccCardholderName = this.$t('validation.invalid', { _field_: this.$t('fields.ccCardholderName') });
                                break;
                            case 'E302':
                                formErrors.ccSecurityCode = this.$t('validation.invalid', { _field_: this.$t('fields.ccSecurityCode') });
                                break;
                            }
                        }

                        this.$refs.form.setErrors(formErrors);
                    }
                });
            }
        }
    };
</script>
